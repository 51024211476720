var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article-container"},[_c('div',{staticClass:"main-wrap"},[(_vm.loading)?_c('div',{staticClass:"loading-wrap"},[_c('van-loading',{attrs:{"color":"#3296fa","vertical":""}},[_vm._v("مازمۇن ىزدەلۋدە...")])],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"article-detail"},[_c('van-nav-bar',{attrs:{"title":"Titalee yoga school","fixed":""},on:{"click-left":function($event){return _vm.$router.back()},"click-right":function($event){return _vm.$router.push('/')}}},[_c('div',{staticStyle:{"color":"#fff","font-size":"16px"},attrs:{"slot":"left"},slot:"left"},[_vm._v(" قايتۋ "),_c('van-icon',{attrs:{"name":"arrow-left","size":"16px"}})],1),_c('div',{staticStyle:{"color":"#fff","font-size":"16px"},attrs:{"slot":"right"},slot:"right"},[_c('van-icon',{attrs:{"name":"wap-home-o","size":"18px"}}),_vm._v(" باس بەت ")],1)]),_c('h1',{staticClass:"article-title"},[_vm._v(_vm._s(_vm.article.title))]),(_vm.detail.type === 'video')?_c('div',{staticClass:"videoControl"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":true,"options":_vm.playerOptions},on:{"timeupdate":function($event){return _vm.onPlayerTimeupdate($event)}}})],1):(_vm.detail.type === 'audio')?_c('div',{staticClass:"audioControl"},[_c('aplayer',{ref:"aplayer",attrs:{"audio":_vm.audio,"mini":false,"lrcType":0,"volume":_vm.volume,"listFolded":true},on:{"timeupdate":_vm.onTimeupdate}})],1):_vm._e(),(_vm.detail.seckill_status === 1 && _vm.detail.is_free == 1)?_c('div',{staticStyle:{"font-family":"'Kerwen Tor'"}},[_c('van-divider',{style:({
            color: '#ee0a24',
            borderColor: '#ee0a24',
            padding: '0 16px',
            fontSize: '16px',
            marginBottom: '0',
          })},[_vm._v(_vm._s(_vm.detail.seckill_name))]),_c('count-down',{attrs:{"endTime":_vm.vipTime,"endText":"قيمىل اياقتالدى. كەلەر جولعى قيمىلعا قاتىسىڭىز!"}}),_c('p',{staticClass:"vip-desc"},[_vm._v("قيمىل باعاسى: "+_vm._s(_vm.detail.seckill_price)+" يۋان")])],1):_vm._e(),(_vm.article.article_text)?_c('div',{ref:"article-content",staticClass:"article-content markdown-body",domProps:{"innerHTML":_vm._s(_vm.article.article_text)}}):_vm._e()],1):_vm._e(),(_vm.detail.is_free == 1)?_c('div',{staticClass:"appreciates"},[_c('van-button',{attrs:{"type":"danger","round":""},on:{"click":_vm._pay}},[_vm._v("تولىعىن ساتىپ الۋ")])],1):_vm._e()]),(_vm.lessons.length > 1)?_c('van-tabs',{staticClass:"tabs",attrs:{"color":"#38f"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{staticClass:"list",attrs:{"title":"ءتىزىم"}},[_c('van-notice-bar',{attrs:{"wrapable":"","scrollable":false}},[_vm._v("ساباقتاردى ساتىپ العانسىن سايكەستى ساباقتىڭ ءۇستىن باسىپ سوسىن جوعارىداعى كەسكىننىڭ ءۇستىن باسىپ كورەسىز. قۇلپى اشىلعان ساباقتاردىڭ ءبارى تەگىن كورىلەدى.")]),_vm._l((_vm.lessons),function(article,index){return _c('van-cell',{key:index,staticClass:"article-item",on:{"click":function($event){return _vm.onSelect(article)}}},[_c('div',{staticClass:"title van-multi-ellipsis--l2",class:_vm.detail.is_free == 1 && article.is_free == 2 ? 'text-grey' : '',attrs:{"slot":"title"},slot:"title"},[_c('p',{class:article.id == _vm.currentId ? 'text-blue' : '',staticStyle:{"display":"inline-block","margin-left":"auto"}},[_vm._v(" "+_vm._s(article.title)+" ")]),(
              (_vm.detail.is_free == 1 && article.is_free == 1) ||
              (_vm.detail.is_free == 2 && article.is_free == 2) ||
              (_vm.detail.is_free == 2 && article.is_free == 1)
            )?_c('i',{staticClass:"icon-lock-open1",staticStyle:{"color":"#999"}}):_vm._e(),(_vm.detail.is_free == 1 && article.is_free == 2)?_c('i',{staticClass:"icon-lock"}):_vm._e()])])})],2),_c('van-tab',{staticClass:"description",attrs:{"title":"تۇسىنكتەمە"}},[(_vm.detail.description)?_c('div',{staticClass:"markdown-body",domProps:{"innerHTML":_vm._s(_vm.detail.description)}}):_c('p',[_vm._v("قازىرشە تۇسنىكتەمە جازىلمادى!")])])],1):_vm._e(),(_vm.detail.teacher)?[_vm._m(0),_c('div',{staticClass:"teacher",on:{"click":function($event){return _vm.$router.push({
          name: 'teacher',
          params: { teacher_id: _vm.detail.teacher.id },
        })}}},[_c('van-image',{staticClass:"avatar",attrs:{"lazy-load":"","round":"","src":_vm.detail.teacher.avatar
            ? _vm.detail.teacher.avatar
            : '../../assets/avatar.jpg',"fit":"cover"}}),_c('div',[_c('h2',[_vm._v(_vm._s(_vm.detail.teacher.title))]),_c('p',[_vm._v(_vm._s(_vm.detail.teacher.intro))])])],1)]:_vm._e(),(_vm.courses.length)?_c('div',{staticClass:"hd-title"},[_c('span',{staticClass:"name"},[_vm._v("قاتىستى جولدامالار")])]):_vm._e(),_c('CourseItem',{attrs:{"hots":_vm.courses}}),_c('div',{staticStyle:{"height":"10px"}}),_c('van-popup',{staticClass:"payContainer",model:{value:(_vm.showPay),callback:function ($$v) {_vm.showPay=$$v},expression:"showPay"}},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.detail.title))]),_c('p',{staticClass:"p1"},[_vm._v(" جالپى "),_c('span',[_vm._v(_vm._s(_vm.lessons.length))]),_vm._v(" مازمۇن، باعاسى "),_c('span',[_vm._v(_vm._s(_vm.detail.price))]),_vm._v(" يۋان ")]),(_vm.detail.is_vip == 1)?_c('p',{staticClass:"p3"},[_vm._v(" (VIP مۇشە بولساڭىز تەگىن كورەسىز) ")]):_vm._e(),(_vm.detail.is_vip == 2)?_c('p',{staticClass:"p3"},[_vm._v(" (بۇل مازمۇن تەك جەكە ساتىلادى) ")]):_vm._e(),_c('p',{staticClass:"p4"},[(_vm.detail.is_vip == 1)?[_c('van-button',{attrs:{"type":"danger","round":"","size":"small"},on:{"click":_vm._pay}},[_vm._v("جەكە ساتىپ الۋ")]),_c('van-button',{attrs:{"type":"primary","round":"","to":"/vip","size":"small"}},[_vm._v("VIP مۇشە بولۋ")])]:_vm._e(),(_vm.detail.is_vip == 2)?_c('van-button',{staticClass:"singleBuy",attrs:{"block":"","type":"danger","round":"","size":"small"},on:{"click":_vm._pay}},[_vm._v("جەكە ساتىپ الۋ")]):_vm._e()],2)])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hd-title"},[_c('span',{staticClass:"name"},[_vm._v("اۆتور تۋرالى")])])}]

export { render, staticRenderFns }